<template>
  <div>
    <form
      class="form"
      @submit.stop.prevent="formOnsubmit()"
    >
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama Bahan"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- <b-form-group
          id="input-group-posyandu"
          label="Posyandu:"
          label-for="input-posyandu"
        >
          <treeselect
            v-model="form.posyandu_id"
            :multiple="false"
            placeholder="Pilih Posyandu"
            :options="posyandu"
          />
          <small class="text-danger">{{ error.posyandu_id }}</small>
        </b-form-group> -->

        <b-form-group id="input-group-description">
          <label for="input-description">Keterangan: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-description"
            v-model="form.description"
            placeholder="Keterangan"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.description }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button
            type="submit"
            variant="primary"
          >Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/material-stock')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>

import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    form: Object,
    route: String,
    title: String,    
  },

  data() {
    return {
      error: {
        posyandu_id: "",
        name: "",
        description: ""
      },
      posyandu: []
    };
  },

  methods: {

    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu = response.data;
        this.posyandu.unshift({
          label: "Pilih Posyandu",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      this.form.email = this.form.registration_number
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push('/material-stock')
      }
    },

  },

  mounted() {    
    this.getPosyanduOption()
  }

};
</script>